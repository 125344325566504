import React from "react"
import Layout from "../components/layout"
import parse from 'html-react-parser'

const ShopifyBlog = ({ pageContext }) => {

    return ( 
        <Layout
            title = { pageContext.title }
        >
            <div className = "site-About">
                <div 
                    style = {{
                        width: '100vw',
                        marginTop: '10vh',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <h2>{ pageContext.title }</h2>
                    <p align = 'left'
                        style = {{
                            whiteSpace : 'pre-line',
                            width: '70vw',
                            marginTop: '10vh',
                            minHeight : '40vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        { parse( pageContext.content ) }
                    </p>
                </div>
            </div>
        </Layout>
    )
}
export default ShopifyBlog
